<template>
  <b-container id="consent-page">
    <vue-headful :title="title + ($appName ? ' | ' + $appName : '')" />
    <div v-if="fetching">
      <Busy primary :size="3" text="Loading..." />
    </div>
    <div v-else>
      <!-- Top Row (form) -->
      <b-row align-v="center" class="text-center">
        <b-col md="5" class="reducer-box-shadow rounded p-4">
          <!-- After Submission -->
          <div v-if="submitted">
            <div v-if="optIn">
              <h3>Thank you for choosing Reducer!</h3>
              <h3 class="mt-1">We'll be in touch with you soon.</h3>
            </div>

            <div v-if="optOut">
              <h4>Opt-Out Successful</h4>
            </div>
          </div>
          <div v-else>
            <!-- OPT-IN -->
            <div v-if="optIn">
              <h4>
                Join Reducer for
                <b>free and easy savings</b> on your business costs
              </h4>
            </div>
            <!-- /OPT-IN -->
            <!-- /After Submission -->
            <!-- OPT-OUT -->
            <div v-if="optOut">
              <h4>Not Interested? No problem.</h4>
              <p>Fill out the form below for your information to be excluded from the system.</p>
            </div>
            <!-- /OPT-OUT -->
            <!-- SHARED -->
            <!-- Form -->
            <b-form @submit="onSubmit">
              <b-form-group>
                <b-form-input
                  v-model="consent.name"
                  required
                  :disabled="submitting"
                  maxlength="100"
                  placeholder="Your Name"
                ></b-form-input>
              </b-form-group>
              <b-form-group>
                <b-form-input
                  v-model="consent.email"
                  type="email"
                  required
                  :disabled="submitting"
                  placeholder="Your Email Address"
                  maxlength="100"
                ></b-form-input>
              </b-form-group>
              <b-form-group>
                <b-form-input
                  v-model="consent.businessName"
                  required
                  :disabled="submitting"
                  placeholder="Business Name"
                  maxlength="100"
                ></b-form-input>
              </b-form-group>
              <b-form-group v-if="isMersthamFcPage">
                <b-form-input
                  v-model="consent.phoneNumber"
                  required
                  :disabled="submitting"
                  placeholder="Phone Number"
                  maxlength="26"
                  type="number"
                  no-wheel
                ></b-form-input>
              </b-form-group>
              <!-- OPT OUT - Reasong for opting out -->
              <b-form-group v-if="optOut">
                <b-form-textarea
                  rows="2"
                  no-resize
                  v-model="consent.reason"
                  :disabled="submitting"
                  placeholder="Reason for Opting Out (optional)"
                  maxlength="250"
                ></b-form-textarea>
              </b-form-group>
              <!-- /OPT-OUT -->
              <p class="text-danger text-left">{{ error }}</p>
              <b-button class="pinkButton" type="submit" block>
                <div v-if="submitting">
                  <Busy light :margin="0" />
                </div>
                <div v-else>{{ optIn ? "Find me savings!" : "Opt-out" }}</div>
              </b-button>
            </b-form>
            <!-- /Form -->
          </div>
          <p :class="submitted ? 'my-4 tinyText' : 'mt-2 tinyText'">
            If you'd like more information, go to our
            <a href="https://www.reducer.co.uk" class="blue">website</a>, drop us an
            <b-link
              :href="`mailto:${isMersthamFcPage ? 'mersthamfc' : 'hello'}@reducer.co.uk`"
              class="blue"
              >email</b-link
            >, or give us a call at
            <b>{{ isMersthamFcPage ? "+44 77 8441 9358" : "+44 20 4571 7704" }}</b>
          </p>
        </b-col>

        <b-col md="7" class="my-sm-0 my-4">
          <img
            v-if="isMersthamFcPage"
            class="mb-3"
            src="@/assets/images/mersthamfc.png"
            alt="Merstham FC logo"
            height="120"
          />

          <h3 v-if="isMersthamFcPage">Reducer are proud sponsors of Merstham FC</h3>
          <h3 v-else>Your accountant has partnered with us because:</h3>
          <div class="smallText">
            <p>
              On average, businesses save
              <b>{{ $currencySymbol }}1,851 a year</b> on their bills by using our service.
              <br />
            </p>
            <p>
              There’s no cost at any point - the service is
              <b>entirely free</b>.
            </p>
            <p>
              Reducer has already found businesses over
              <b>{{ $currencySymbol }}3,600,000</b> in savings.
            </p>
            <p>We're trusted by over <b>9,000</b> businesses across the UK.</p>
          </div>

          <XeroTrustpilotReviews class="mt-4" />

          <div v-if="optOut">
            <hr class="mx-5" />
            <p class="tinyText">
              If you'd still like to opt out, fill in the form and you'll be excluded from our
              system.
            </p>
          </div>
        </b-col>
      </b-row>

      <div class="px-0 mt-3 mt-lg-5">
        <h1>How it works</h1>

        <DotCards
          class="pt-lg-3"
          :text1="
            isMersthamFcPage
              ? `We will call you and get your utility bills, either by connecting to Xero or simply via email.`
              : `Your accountant will onboard your business to Reducer for a <b>cost-saving analysis</b>.`
          "
          text2="We'll compare your business' costs against hundreds of suppliers to find the <b>cheapest deals</b>."
          :text3="
            `We will send you an email with a link to your <b>personalised</b> cost savings report. <br><a href='/app/demo' target='_blank'>See how a demo report looks like</a>`
          "
        />
      </div>

      <FAQs :faqs="faqs" />

      <BusinessCarousel class="mt-3 mt-lg-5" />
    </div>
  </b-container>
</template>

<script>
import ApiHelper from "@/helper/apihelper";
import Console from "@/console";
import { EventBus } from "@/components/eventbus";
import Busy from "@/components/Busy";
import DotCards from "@/components/DotCards";
import BusinessCarousel from "@/components/BusinessCarousel";
import FAQs from "@/components/FAQs";
import XeroTrustpilotReviews from "@/components/XeroTrustpilotReviews";

export default {
  name: "consent-lists",
  components: { Busy, DotCards, BusinessCarousel, FAQs, XeroTrustpilotReviews },
  data() {
    return {
      title: "Consent List",
      consentListId: this.$route.params.consentListId,
      optIn: null,
      optOut: null,
      fetching: null,
      submitting: null,
      submitted: null,
      consent: {},
      error: null,
      isMersthamFcPage:
        this.$route.params.consentListId == "97194ed6-7746-46a0-8af2-c344333c02e2" ||
        this.$route.params.consentListId == "13bb9f32-93ae-4732-af92-f8fcbe2f6bb5" ||
        this.$route.params.consentListId == "38cec002-8bc3-4652-9fa0-3ed85f77d866",
      faqs: [
        {
          index: 1,
          q: "Which spend areas does Reducer compare?",
          a:
            "Our service currently finds savings in the following areas: Electricity, Gas, Water and Sewerage, Waste and Recycling, Payment Processing, Broadband and Telecoms, and Mobiles."
        },
        {
          index: 2,
          q: "How long does it take?",
          a:
            "We want to give every single customer the best deals across as many areas as possible. It can take a few days for us to finalise your savings. This is mainly due to some of our suppliers taking a while to produce their quotes, particularly in areas like waste management where the quoting process is not as advanced as other areas we operate in. As soon as you've connected your organisation we will start gathering quotes, and we will email you as soon as your report is ready."
        },
        {
          index: 3,
          q: "How much does it cost / How do you make money?",
          a:
            "Reducer is free to use. We work in exactly the same way a price comparison site you might use for your home utilities does. Suppliers pay us a small fee if and when you switch to them."
        },
        {
          index: 4,
          q: "What data do you take from accounting platforms?",
          a: `When you connect us to your Xero or QuickBooks account, our system takes 13 months of your spending data, which consists of invoice summaries, line items and attachments. We exclude any spend data that is associated with a salary or wage account code. We also take high level organisation data such as your business address and business type.
Our system operates on a read-only basis, we will never write any data into your accounting platform.`
        }
      ]
    };
  },
  created() {
    this.fetchData();
  },

  methods: {
    async fetchData() {
      let client = await ApiHelper.http();
      await client
        .get(`${ApiHelper.endPoint()}consentlists/${this.consentListId}/type`)
        .then(response => {
          Console.log(response);
          if (response.data.consentType === "optIn") {
            this.optIn = true;
            this.fetching = false;
          } else if (response.data.consentType === "optOut") {
            this.optOut = true;
            this.fetching = false;
          } else {
            throw "Unrecognized consent type: " + response.data.consentType;
          }
        })
        .catch(e => {
          this.fetching = false;
          this.showWarning("There was a problem communicating with server.");
          Console.error(e);
        });
    },
    async onSubmit(evt) {
      this.error = null;
      evt.preventDefault();
      this.submitting = true;
      let client = await ApiHelper.http();
      await client
        .post(`${ApiHelper.endPoint()}consentlists/${this.consentListId}/consent`, {
          consent: this.consent
        })
        .then(response => {
          Console.log(response);
          this.submitting = false;
          this.submitted = true;
        })
        .catch(e => {
          Console.error(e);
          if (e.response.status === 409) {
            this.showSuccess("Your consent has already been provided.");
          } else if (e.response.status === 400) {
            this.error = e.response.data.message;
          } else {
            this.showWarning("There was a problem submitting your data. Please try again later.");
          }
          this.submitting = false;
        });
    },
    showWarning(message) {
      EventBus.$emit("show-toast", { message: message, variant: "warning" });
    },
    showSuccess(message) {
      EventBus.$emit("show-toast", { message: message, variant: "success" });
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/common.scss";
#consent-page {
  h1 {
    text-align: center;
    font-size: 2rem;
  }
  .consent .icon {
    height: 6rem;
    width: 6rem;
    padding-bottom: 1rem;
  }
  .bottomRow img {
    width: 5rem;
  }
  .logoRow img {
    width: 4.5rem;
  }
  .tinyText {
    font-size: 0.9rem;
    color: $color-font-para;
    padding: 0;
  }
  .smallText {
    font-size: 1.1rem;
  }
}
</style>
