<template>
  <div id="business-carousel">
    <h1 class="mb-2 mb-lg-4">What our clients have to say</h1>

    <div class="d-flex align-items-stretch justify-content-center">
      <div class="chevron-container" @click="moveCarousel('down')">
        <font-awesome-icon class="chevron-icon mr-3" :icon="['fas', 'chevron-left']" />
      </div>
      <b-row class="testimonial-card">
        <b-col cols="12" lg="3"
          ><img
            class="owner-image reducer-box-shadow"
            border="0"
            v-if="selectedBusiness.owner"
            :src="getImgUrl(selectedBusiness.owner)"
          />

          <img
            class="business-image reducer-box-shadow"
            border="0"
            v-if="selectedBusiness.business"
            :src="getImgUrl(selectedBusiness.business)"
          />
        </b-col>
        <b-col cols="12" lg="9">
          <p class="text-left">
            {{ selectedBusiness.quote }}
          </p>
          <p class="float-right">{{ selectedBusiness.by }}</p>
        </b-col>
      </b-row>
      <div class="chevron-container" @click="moveCarousel('up')">
        <font-awesome-icon class="chevron-icon ml-3" :icon="['fas', 'chevron-right']" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BusinessCarousel",
  mounted() {
    this.moveCarousel("up");
    this.carouselInterval = window.setInterval(() => {
      this.moveCarousel("up");
    }, 8000);
  },
  data() {
    return {
      carouselInterval: null,
      selectedBusinessIndex: 0,
      selectedBusiness: {},
      businesses: [
        {
          business: "crafty_belle.png",
          owner: "josh_mullet_sadones.png",
          quote:
            "Reducer's platform is so easy to read and understand. It has a clean and clear design which means there are no surprises when it comes to renewals charges. They're amazingly helpful and they're always on hand to explain things to me should I have any questions.",
          by: "- Josh Mullet-Sadones, Crafty Belle"
        },
        {
          business: "ab10_clinic_square.png",
          owner: "jacqueline_davidson.png",
          quote:
            "Thanks to Reducer, I’ve made some huge cuts on my bills, so I can use the money I’ve saved on more important things.",
          by: "- Jacqueline Davidson, AB10 Clinic"
        },
        {
          business: "facepunch_studios_square.png",
          owner: null,
          quote:
            "I was very impressed with how simple this service is. I'm very glad I tried it as it has saved me over £4,000 per year on my electricity, so I would definitely recommend it.",
          by: "- Craig Gwilt, Facepunch Studios"
        },
        {
          business: "greenwood_property_square.png",
          owner: "james_grimsted.png",
          quote:
            "Reducer's responsive and helpful staff have delivered tangible reductions in cost for my businesses.",
          by: "- James Grimstead, Barons & Greenwoods Property Centre"
        },
        {
          business: "runway_east.jpg",
          owner: "andy_williams.jpeg",
          quote:
            "Reducer is a brilliant tool for any finance team or small business owner - we've saved thousands of pounds to date. The Reducer team handled just about everything for us and from our perspective the ROI on 5 minutes of set up is a no-brainer!",
          by: "- Andy Williams, Runway East "
        },
        {
          business: "canburysecret.png",
          owner: "selini.jpg",
          quote:
            "I'm very happy with the service from Reducer - very smooth and professionally managed. They provided me with plenty of options and worked around the needs of my business. ",
          by: "- Filippo Selini, Canbury Secret"
        },
        {
          business: "cafe_roya.png",
          owner: "royabishop.jpg",
          quote:
            "Reducer provided a quick and efficient service and helped save me money on my energy bills. The switch couldn't have been easier. I would highly recommend Reducer. ",
          by: "- Roya Bishop, Cafe Roya"
        },
        {
          business: "wild_beer.jpg",
          owner: null,
          quote:
            "Reducer have made it so easy to save money for our business. They have really taken the headache away of managing our supplier contracts!",
          by: "- Tori Knowles, Wild Beer co"
        },
        {
          business: "bdb.png",
          owner: "liz_coulter.jpg",
          quote:
            "I like the idea of being able to lock in prices for contracts up to one year before a switch and for the duration of the contract.  I have been stung in the past where suppliers have increased my pricing mid contract. Reducer have been very helpful in explaining the process of switching and then the future handling of my account.",
          by: "- Liz Coulter, Beautifully Different"
        },
        {
          business: "bobabox.png",
          owner: "yoshi_lee.jpg",
          quote:
            "I highly recommend Reducer. They were super helpful, going above and beyond our expectations when reviewing our rates and contracts. They have literally saved us thousands of pounds per month.",
          by: "- Yoshi Lee, Boba Box"
        }
      ]
    };
  },
  methods: {
    getImgUrl(img_name) {
      return require("@/assets/images/businesses/" + img_name);
    },
    moveCarousel(way) {
      if (way == "up") {
        if (this.selectedBusinessIndex == this.businesses.length - 1) {
          this.selectedBusinessIndex = 0;
        } else {
          this.selectedBusinessIndex++;
        }
      } else {
        if (this.selectedBusinessIndex == 0) {
          this.selectedBusinessIndex = this.businesses.length - 1;
        } else {
          this.selectedBusinessIndex--;
        }
      }

      this.selectedBusiness = this.businesses[this.selectedBusinessIndex];
    }
  },
  beforeDestroy() {
    clearInterval(this.carouselInterval);
  }
};
</script>

<style lang="scss">
@import "@/styles/common.scss";
#business-carousel {
  text-align: center;

  .chevron-container {
    display: flex;
    align-items: center;
    transition: all 0.3s ease-in-out;

    &:hover {
      cursor: pointer;
      .chevron-icon {
        color: $color-pink-lighter5;
      }
    }

    .chevron-icon {
      color: $color-pink-lighter8;
      font-size: 7rem;
      margin-bottom: 2rem;
      display: inline-block !important;

      @media screen and (max-width: 767px) {
        font-size: 5rem;
      }
    }
  }

  .testimonial-card {
    font-size: 18px;
    border-radius: 10px;
    padding: 2rem;
    width: 50rem;
    min-height: 15rem;
    position: relative;

    .owner-image {
      position: absolute;
      top: 60px;
      left: 80px;
      width: 70px;
      height: 70px;
      border-radius: 50%;
    }
    .business-image {
      max-width: 6rem;
      border-radius: 50%;
    }

    @media screen and (max-width: 992px) {
      text-align: center !important;
      min-height: 22rem;
      .owner-image {
        top: 40px;
        left: 260px;
      }
      .business-image {
        margin-bottom: 1.5rem;
      }
    }

    @media screen and (max-width: 767px) {
      min-height: 27rem;
      .owner-image {
        left: 50%;
      }
    }
  }
}
</style>
