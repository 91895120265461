<template>
  <div id="xero-trustpilot-reviews" :class="'d-flex align-items-center px-4 stacked-' + stacked">
    <a
      href="https://apps.xero.com/uk/app/reducer/reviews"
      target="_blank"
      class="d-flex align-items-center xero-rating text-decoration-none"
    >
      <b-img
        :src="require('@/assets/images/xero.png')"
        alt="Xero logo"
        height="50"
        class="mr-3 rounded-circle reducer-box-shadow"
      />
      <div class="text-left">
        <div class="star-rating d-flex align-items-center justify-content-center">
          <h5 class="m-0"><b>5.0</b></h5>
          <font-awesome-icon :icon="['fas', 'star']" class="ml-2" />
          <font-awesome-icon :icon="['fas', 'star']" class="ml-2" />
          <font-awesome-icon :icon="['fas', 'star']" class="ml-2" />
          <font-awesome-icon :icon="['fas', 'star']" class="ml-2" />
          <font-awesome-icon :icon="['fas', 'star']" class="ml-2" />
        </div>
        <span class="text-muted small">140+ Reviews on Xero</span>
      </div>
    </a>
    <a
      v-if="showTrustpilot"
      href="https://uk.trustpilot.com/review/www.reducer.co.uk"
      target="_blank"
      class="d-flex align-items-center text-decoration-none trustpilot"
    >
      <b-img
        :src="require('@/assets/images/trustpilot_star_white.png')"
        alt="Trustpilot logo"
        height="50"
        class="mr-3 reducer-box-shadow rounded-circle"
      />
      <div class="text-left">
        <div class="star-rating d-flex align-items-center justify-content-center">
          <h5 class="m-0 pr-2"><b>4.8</b></h5>
          <b-img
            :src="require('@/assets/images/trustpilot_stars.svg')"
            alt="Trustpilot 5 stars"
            height="25"
          />
        </div>
        <span class="text-muted small">110+ Reviews on Trustpilot</span>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: "XeroTrustpilotReviews",
  props: {
    showTrustpilot: {
      type: Boolean,
      default: true
    },
    stacked: Boolean
  }
};
</script>

<style lang="scss">
@import "@/styles/common.scss";
#xero-trustpilot-reviews {
  justify-content: space-around;
  &.stacked-true {
    flex-flow: column;
    .trustpilot {
      margin-top: 1rem;
    }
  }
  @media only screen and (max-width: 1200px) {
    flex-flow: column;
    .trustpilot {
      margin-top: 1rem;
    }
  }
  @media only screen and (max-width: 767px) {
    a {
      cursor: not-allowed;
      pointer-events: none;
    }
    span {
      text-decoration: none !important;
    }
  }
  a:hover {
    span {
      text-decoration: underline;
    }
  }
  .star-rating {
    svg {
      color: $color-yellow-red1;
      font-size: 19px;
    }
  }
}
</style>
