<template>
  <div id="faq-section" class="px-0">
    <h1>Frequently Asked Questions</h1>

    <div class="d-flex flex-wrap justify-content-center mt-4 ">
      <div class="faqs my-2 p-1 reducer-box-shadow" v-for="faq in faqs" :key="faq.index">
        <b-button v-b-toggle="'collapse-' + faq.index" class="faq" variant="outline-secondary">
          <span class="ml-2 ml-md-3">{{ faq.q }}</span>
          <font-awesome-icon
            class="mx-1 mx-md-3 faq-chevron reducer-pink float-right"
            :icon="['fas', 'chevron-down']"
        /></b-button>
        <b-collapse :id="'collapse-' + faq.index" class="faq-answer mt-2 py-2 px-3">
          <p class="faq-answer float-left">{{ faq.a }}</p>
        </b-collapse>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FAQs",
  props: {
    faqs: Array
  }
};
</script>

<style lang="scss">
@import "@/styles/common.scss";
#faq-section {
  text-align: center;
  padding: 2rem;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;

  p,
  span {
    font-size: 18px;
  }

  p {
    margin-left: 1rem;
  }

  .faqs {
    width: 100%;

    .faq {
      border-color: white;
      width: 100%;
      text-align: left !important;
      .faq-chevron {
        color: $color-pink-lighter5;
        font-size: 1.5rem;
        font-weight: bold;
      }

      &:hover {
        background-color: white;
        color: $color-font-headings;
      }
    }
    .faq-answer {
      text-align: left;
      white-space: pre-wrap;
    }
  }
}
</style>
